<template>
  <section id="about">
    <TopBanner
      v-if="nowPageData"
      :mainBg="nowPageData.mainBg"
      :pageName="nowPageData.pageName"
      :titleWord="nowPageData.titleWord"
      :bottomBgColor="nowPageData.bottomBgColor"/>

    <router-view/>

    <SiblingNavbox
      :siblingData="siblingData"/>
  </section>
</template>

<script>
import { aboutPageData } from '@/utils/aboutPageData';
import TopBanner from '@/components/public/TopBanner.vue';
import SiblingNavbox from '@/components/public/SiblingNavbox.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'About',
  mixins: [titleMixin],
  components: {
    TopBanner,
    SiblingNavbox,
  },
  data() {
    return {
      aboutPageData,
    };
  },
  computed: {
    nowPageData() {
      return aboutPageData.find(
        (item) => item.routerName === this.$route.name,
      );
    },
    siblingData() {
      return aboutPageData
        .filter((item) => item.routerName !== this.$route.name)
        .map((item) => ({
          pic: item.mainBg,
          title: item.pageNameEn,
          subTitle: item.pageName,
          route: {
            name: item.routerName,
          },
        }));
    },
  },
};
</script>

<style
  scoped
  src="@/assets/scss/about.scss"
  lang="scss">
</style>
